<template>
  <div id="hkindex">
    <div class="header">
      <img src="@/assets/hk-header.png">
    </div>
    <div class="top-nav">
      <span><a href="#">About Us</a></span>
      <span><a href="#">Contact Us</a></span>
      <span><a href="#">繁</a></span>
      <span style="border-right: 0"><a href="#">简</a></span>
    </div>
    <div class="nav">
      <img src="../../assets/logo_hangseng.png">
      <div class="menu">
        <ul>
          <li><a href="#">Indexes</a></li>
          <li><a href="#">Our Services</a></li>
          <li><a href="#">ESG Service</a></li>
          <li><a href="#">Resources</a></li>
          <li><a href="#">Newsroom</a></li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div class="arrow-left">
        <span>
          <i class="fas fa-chevron-left"></i>
        </span>
      </div>
      <div class="index-box">
        <div class="index-tab">
          <ul>
            <li class="first">
              HONG KONG-LISTED
              <div class="arrow"></div>
            </li>
            <li class="second">MAINLAND-LISTED</li>
            <li class="third">CROSS-MARKET</li>
          </ul>
        </div>
        <div class="index-cont row m-0">
          <div class="col-3 px-2 mt-3 mb-3 border-right d-flex">
            <div class="arrow">
              <div :class="[isHsiPriceUp?'up':'down']"></div>
            </div>
            <div class="box-number">
              <div class="title">Hang Seng Index</div>
              <div class="d-flex">
                <div style="font-size: 25px; font-weight: bold; flex: 1">{{ hsiPrice }}</div>
                <div :class="[isHsiPriceUp?'change-u':'change-d']">
                  <p class="m-0 text-right index-change" style="line-height: 1.3;">{{ hsiDiff }}</p>
                  <p class="m-0 text-right" style="line-height: 1.3;">{{ hsiPer }}%</p>
                </div>
              </div>
              <div class="text-right mt-2" style="font-size: 90%">
                Last Update: {{ dispDatetime }}
              </div>
            </div>
          </div>
          <div class="col-3 px-2 mt-3 mb-3 border-right d-flex">
            <div class="arrow">
              <div :class="[isHscnPriceUp?'up':'down']"></div>
            </div>
            <div class="box-number">
              <div class="title">Hang Seng China Enterprises Index</div>
              <div class="d-flex">
                <div style="font-size: 25px; font-weight: bold; flex: 1">{{ hscnPrice }}</div>
                <div :class="[isHscnPriceUp?'change-u':'change-d']">
                  <p class="m-0 text-right index-change" style="line-height: 1.3;">{{ isHscnPriceUp?'+':'' }}{{ hscnDiff }}</p>
                  <p class="m-0 text-right" style="line-height: 1.3;">{{ isHscnPriceUp?'+':'' }}{{ hscnPer }}%</p>
                </div>
              </div>
              <div class="text-right mt-2" style="font-size: 90%">
                Last Update: {{ dispDatetime }}
              </div>
            </div>
          </div>
          <div class="col-3 px-2 mt-3 mb-3 border-right d-flex">
            <div class="arrow">
              <div :class="[isHstiPriceUp?'up':'down']"></div>
            </div>
            <div class="box-number">
              <div class="title">Hang Seng TECH Index</div>
              <div class="d-flex">
                <div style="font-size: 25px; font-weight: bold; flex: 1">{{ hstiPrice }}</div>
                <div :class="[isHstiPriceUp?'change-u':'change-d']">
                  <p class="m-0 text-right index-change" style="line-height: 1.3;">{{ isHstiPriceUp?'+':'' }}{{ hstiDiff }}</p>
                  <p class="m-0 text-right" style="line-height: 1.3;">{{ isHstiPriceUp?'+':'' }}{{ hstiPer }}%</p>
                </div>
              </div>
              <div class="text-right mt-2" style="font-size: 90%">
                Last Update: {{ dispDatetime }}
              </div>
            </div>
          </div>
          <div class="col-3 px-2 mt-3 mb-3 d-flex">
            <div class="arrow">
              <div :class="[isHsciPriceUp?'up':'down']"></div>
            </div>
            <div class="box-number">
              <div class="title">Hang Seng Composite Index</div>
              <div class="d-flex">
                <div style="font-size: 25px; font-weight: bold; flex: 1">{{ hsciPrice }}</div>
                <div :class="[isHsciPriceUp?'change-u':'change-d']">
                  <p class="m-0 text-right index-change" style="line-height: 1.3;">{{ isHsciPriceUp?'+':'' }}{{ hsciDiff }}</p>
                  <p class="m-0 text-right" style="line-height: 1.3;">{{ isHsciPriceUp?'+':'' }}{{ hsciPer }}%</p>
                </div>
              </div>
              <div class="text-right mt-2" style="font-size: 90%">
                Last Update: {{ dispDatetime }}
              </div>
            </div>
          </div>
        </div>

        <div class="today-result">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>morning</td>
                <td>top</td>
                <td>{{ r1ResultThree }}</td>
                <td>bottom</td>
                <td>{{ r1ResultTwo }}</td>
              </tr>
              <tr>
                <td>evening</td>
                <td>top</td>
                <td>{{ r2ResultThree }}</td>
                <td>bottom</td>
                <td>{{ r2ResultTwo }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="arrow-right">
        <span>
          <i class="fas fa-chevron-right"></i>
        </span>
      </div>
    </div>
    <div class="text-center mt-2">
      <a href="https://hsi-vip.com/" target="_blank" style="font-size: 110%">www.hsi-vip.com</a>
    </div>
    <div class="hk-final-footer"></div>
  </div>
</template>
<script>
import moment from '@/moment.js'
import LottoService from '@/services/lottoService'

export default {
  name: 'HKIndex',
  data() {
    return {
      Date: null,
      Prices: null,
      StartTime: null,
      EndTime: null,
      todayResult: {
        r1: null,
        r2: null
      }
    }
  },
  computed: {
    hsi() {
      if(this.Prices) {
        return {
          price: this.Prices.price,
          diff: this.Prices.diff,
          per: this.Prices.per
        }
      }else{
        return false
      }
    },
    isHsiPriceUp() {
      if(this.hsi) {
        return parseFloat(this.hsi.diff) >= 0
      }else{
        return false
      }
    },
    hsiPrice() {
      if(this.hsi) {
        return parseFloat(this.hsi.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hsiDiff() {
      if(this.hsi) {
        return this.hsi.diff.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hsiPer() {
      if(this.hsi) {
        return this.hsi.per.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },

    hscn() {
      if(this.Prices) {
        return this.Prices.hscn
      }else{
        return false
      }
    },
    isHscnPriceUp() {
      if(this.hscn) {
        return parseFloat(this.hscn.diff) >= 0
      }else{
        return false
      }
    },
    hscnPrice() {
      if(this.hscn) {
        return this.hscn.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hscnDiff() {
      if(this.hscn) {
        return this.hscn.diff.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hscnPer() {
      if(this.hscn) {
        return this.hscn.per.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },

    hsti() {
      if(this.Prices) {
        return this.Prices.hsti
      }else{
        return false
      }
    },
    isHstiPriceUp() {
      if(this.hsti) {
        return parseFloat(this.hsti.diff) >= 0
      }else{
        return false
      }
    },
    hstiPrice() {
      if(this.hsti) {
        return this.hsti.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hstiDiff() {
      if(this.hsti) {
        return this.hsti.diff.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hstiPer() {
      if(this.hsti) {
        return this.hsti.per.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },

    hsci() {
      if(this.Prices) {
        return this.Prices.hsci
      }else{
        return false
      }
    },
    isHsciPriceUp() {
      if(this.hsci) {
        return parseFloat(this.hsci.diff) >= 0
      }else{
        return false
      }
    },
    hsciPrice() {
      if(this.hsci) {
        return this.hsci.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hsciDiff() {
      if(this.hsci) {
        return this.hsci.diff.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    hsciPer() {
      if(this.hsci) {
        return this.hsci.per.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },

    dispDatetime() {
      if(this.Prices) {
        return moment(`${this.Date} ${this.Prices.time}`).format("DD-MM-YYYY HH:mm")
      }else{
        return ''
      }
    },

    r1ResultThree() {
      if(this.todayResult.r1) {
        let split = parseFloat(this.todayResult.r1.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r1ResultTwo() {
      if(this.todayResult.r1) {
        let split = parseFloat(this.todayResult.r1.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultThree() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultTwo() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  methods: {
    updateChart() {
      LottoService
        .getHkData()
        .then((response)=>{
          if(response.status === 'success') {
            this.Date = response.data.date
            this.Prices = response.data.prices
            this.StartTime = response.data.tLabels[0]
            this.EndTime = response.data.tLabels[response.data.tLabels.length - 1]
            this.todayResult = response.data.results
          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(60, 'm').isBetween(moment(`${this.Date} ${this.StartTime}`), moment(`${this.Date} ${this.EndTime}`).add(15, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
#hkindex {
  margin-bottom: 30px;
  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }
  .top-nav {
    margin-top: -4px;
    border-top: 4px solid #0D6C29;
    background-color: #0D6C29;
    text-align: right;
    padding: 5px 0;
    span {
      padding: 0px 10px;
      border-right: 1px solid #FFF;
      a {
        color: #FFF;
      }
    }
  }
  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: 66px;
      margin-left: 20px;
    }
    .menu {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        li {
          list-style: none;
          padding: 5px 10px;
          a {
            font-size: 20px;
            color: #666;
          }
          a:hover {
            color: #539767;
            text-decoration: none;
          }
        }
      }
    }
  }
  .content {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    .arrow-left {
      align-items: center;
      display: flex;
      padding: 5px;
      cursor: pointer;
      span {
        padding: 10px 5px;
        background-color: #CCC;
        color: #FFF;
        border-radius: 2px;
      }
      .fas {
        font-size: 15px;
      }
    }
    .arrow-right {
      align-items: center;
      display: flex;
      padding: 5px;
      cursor: pointer;
      span {
        padding: 10px 5px;
        background-color: #0d6c29;
        color: #FFF;
        border-radius: 2px;
      }
      .fas {
        font-size: 15px;
      }
    }
    .index-box {
      flex: 1;
      .index-tab {
        border-bottom: 3px solid #0d6c29;

        ul {
          padding: 0;
          margin: 0;
          display: flex;
          li {
            list-style: none;
            padding: 8px;
            width: 200px;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            border-radius: 1px;
            &.first {
              background-color: #f6faeb;
              color: #1380bf;
              position: relative;
              .arrow {
                position: absolute;
                //top: 32px;
                left: 96px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 8px 8px;
                border-color: transparent transparent #0d6c29;
                transition: left .3s ease-in-out;
              }
            }
            &.second {
              margin-left: 8px;
              color: #eb2b22;
              background-color: #e9e6e1;
            }
            &.third {
              margin-left: 8px;
              color: #7537c2;
              background-color: #e9e6e1;
            }
          }
        }
      }
      .index-cont {
        min-height: 100px;
        background-color: #F6FAEB;
        .border-right {
          border-right: 2px solid #CCC;
        }
        .arrow {
          padding: 0 20px 0 10px;
          .down {
            top: 0px;
            //left: 30px;
            width: 3px;
            height: 80px;
            background-color: #eb2b22;
            position: relative;
          }
          .down::before {
            content: '';
            position: absolute;
            bottom: 2px;
            left: -6px;
            width: 10px;
            height: 3px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            background-color: #eb2b22;
          }
          .down::after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: -1px;
            width: 10px;
            height: 3px;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            background-color: #eb2b22;
          }
          .up {
            top: 0px;
            //left: 30px;
            width: 3px;
            height: 80px;
            background-color: #0d6c29;
            position: relative;
          }
          .up::before {
            content: '';
            position: absolute;
            top: 2px;
            left: -6px;
            width: 10px;
            height: 3px;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            background-color: #0d6c29;
          }
          .up::after {
            content: '';
            position: absolute;
            top: 2px;
            left: -1px;
            width: 10px;
            height: 3px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            background-color: #0d6c29;
          }
        }
        .box-number {
          flex: 1;

          .title {
            height: 50px;
          }
          .change-d {
            color: #eb2b22;
            .index-change::after {
              content: '▼';
              font-size: 12.5px;
            }
          }

          .change-u {
            color: #0d6c29;
            .index-change::after {
              content: '▲';
              font-size: 12.5px;
            }
          }

        }
      }
      .today-result {
        padding: 10px 10px 10px 10px;
        background-color: #F6FAEB;
        .table {
          margin-bottom: 0px;
        }
        .table>tbody>tr>td {
          width: 20%;
          padding: 5px;
          text-align: center;
          color: #0d6c29;
        }
        .table-bordered th, .table-bordered td {
          border: 1px solid #0d6c29;
        }
      }
    }
  }
  .hk-final-footer {
    margin: 10px 15px 5px;
    height: 8px;
    background-color: #0D6C29;
  }
}

@media (max-width: 991px) {
  #hkindex {
    .nav {
      justify-content: center;
      .menu {
        justify-content: center;
      }
    }
    .content {
      .arrow-left, .arrow-right {
        display: none;
      }
      .index-box {
        .index-cont {
          flex-direction: column;

          .col-3 {
            flex: none;
            max-width: 100%;
            border-bottom: 1px solid #dee2e6!important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #hkindex {
    .nav {
      justify-content: unset;
      .menu {
        display: none;
      }
    }
    .content {
      margin-top: 0;
      .index-box {
        .index-tab {
          ul {
            flex-direction: column;
            li {
              width: 100%;
              margin-left: 0 !important;
              padding-left: 0;

              &.first {
                .arrow {
                  left: calc(100%/2);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
